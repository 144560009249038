<template>
  <div class="container vh-100">
    <div class="row justify-content-center layout">

      <main class="col-9 align-self-center">
        <h1>anrinix</h1>
        <MainLead class="mainlead"/>
        <p>As an experienced Technical Manager, I have led cross-functional teams, collaborated with stakeholders, and
          delivered innovative software solutions for clients ranging from small startups to large enterprises.</p>
        <p>I have expertise in various technologies, project management, process improvement, and team leadership.
          Additionally, I have trained junior developers, continuously improved my skills, and always stayed up-to-date
          with the latest trends and technologies.</p>
        <p>My extensive experience has equipped me with the skills and knowledge to lead successful software development
          projects and deliver solutions that meet client needs.</p>
      </main>

      <footer class="col-9 pt-3 align-self-end d-flex justify-content-between border-top">
        <div class="col-6 justify-content-start d-flex">
          <small class="text-muted copyright">&copy; {{ new Date().getFullYear() }}, anrinix.com</small>
        </div>
        <div class="col-4 justify-content-end d-flex">
          <ul class="list-unstyled d-flex">
            <li class="ms-2"><i class="bi bi-linkedin"></i></li>
            <li class="ms-2"><i class="bi bi-github"></i></li>
          </ul>
        </div>
      </footer>

    </div>
  </div>
</template>

<script>
import MainLead from "@/components/MainLead.vue";

export default {
  name: "MainItem",
  components: {MainLead},
  props: {
    msg: String
  }
}
</script>

<style scoped>
.layout {
  height: 100%;
}

.copyright {
  font-family: 'Roboto', sans-serif;
  font-size: small;
}

.code {
  font-family: 'Roboto Mono', monospace;
}

.mainlead {
  padding-bottom: 3px;
}
</style>